//
// Copyright 2019-2025 Luxembourg Institute of Science and Technology (LIST - http://www.list.lu/).
//
// Author: Olivier Parisot (olivier.parisot@list.lu)
//

const moment=require('moment');


const hoursBarWidth=125;

const timeShiftInHours = (temperature,cultivarSusceptibilityRank) =>
{
  return 713.144-31.701*temperature+((5-cultivarSusceptibilityRank)*125);
};

/*const estimateGrowthStageFromFixedValue = (growthStageMomentDate,growthStageValue,currTSAsMoment) =>
{
  if (currTSAsMoment.isSame(growthStageMomentDate,'day'))
  {
    return growthStageValue;
  }
  else
  {
    return 0;
  }
}*/

const getFictiveSowingDate = (growthStageMomentDate,growthStageValue) =>
{
  //console.info("getFictiveSowingDate() "+growthStageMomentDate+" "+growthStageValue);
  var fictiveSowingDate=moment(growthStageMomentDate);
  fictiveSowingDate.add(-1,'years');
  fictiveSowingDate.set('month',8);
  fictiveSowingDate.set('date',1);
  const endDate=moment(growthStageMomentDate);
  endDate.add(-1,'years');
  endDate.set('month',11);
  endDate.set('date',1);
  while (fictiveSowingDate.isBefore(endDate))
  {
    const estimatedGrowthStage=estimateGrowthStageFromSowingDate(fictiveSowingDate,growthStageMomentDate);
    //console.info(estimatedGrowthStage+" "+growthStageValue);
    if (estimatedGrowthStage<=growthStageValue) break;
    fictiveSowingDate.add(1,'days');
  }
  //console.info("getFictiveSowingDate() -> "+moment(fictiveSowingDate).format());
  return fictiveSowingDate;
}

const estimateGrowthStageFromSowingDate = (sowingDateAsMoment,currTSAsMoment) =>
{
  const x0=238.5619;
  const x=currTSAsMoment.diff(sowingDateAsMoment,'days');
  const a=57.0022;
  const b=12.5482;
  const y0=28.7191;
  const f=y0+(a/(1+Math.exp(-(x-x0)/b)));
  return Math.round(f);
}

const isAfterGrowingPeriod = (growingStage) =>
{
  return growingStage>69;
}

const isBeforeGrowingPeriod = (growingStage) =>
{
  return growingStage<31;
}

const isDuringGrowingPeriod = (growingStage) =>
{
  return !isBeforeGrowingPeriod(growingStage)&&!isAfterGrowingPeriod(growingStage);
}

const isSprayBarSuspect = (val) =>
{
  return val>=15;
};

const isSuspectTempAndPrec = (temperature,precipitation) =>
{
  if (temperature<=6.58) return false;
  if (precipitation<=0) return false;
  return true;
};

const isProbablyMissingTempAndPrec = (temperature,precipitation) =>
{
  return (temperature===0) && (precipitation===0);
};

module.exports =
{
  hoursBarWidth: hoursBarWidth,
  timeShiftInHours: timeShiftInHours,
  estimateGrowthStageFromSowingDate: estimateGrowthStageFromSowingDate,
  getFictiveSowingDate: getFictiveSowingDate,
  isAfterGrowingPeriod: isAfterGrowingPeriod,
  isBeforeGrowingPeriod: isBeforeGrowingPeriod,
  isDuringGrowingPeriod: isDuringGrowingPeriod,
  isSprayBarSuspect: isSprayBarSuspect,
  isSuspectTempAndPrec: isSuspectTempAndPrec,
  isProbablyMissingTempAndPrec: isProbablyMissingTempAndPrec
};
